import Vue from 'vue'
import Vuex from 'vuex'

import { eventBus } from '../main'
import router from '../router/index.js'
import { stat } from 'fs';
import { i18n } from '../i18n.js'


Vue.use(Vuex)

function cloneAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
        return obj;
    }
    var temp = (obj instanceof Array) ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (var key in obj) {
        temp[key] = cloneAsObject(obj[key]);
    }
    return temp;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const qParams = Object.fromEntries(urlSearchParams.entries());


export const store = new Vuex.Store({
    state: {
        isAnonymous: false,

        socket: {
            isConnected: false,
            clubAdmin: false,
            clubAdminTables: [],
            message: '',
            reconnectError: false,
        },
        tableToken: "",
        isLoggedIn: false,
        loggedInUser: null,
        domainRESTPrefix: '/api/v1',
        currentDomain: `${window.location.protocol}//${window.location.hostname}`,
        geoLocation: null,

        playerStats: {
            HighestBreak: 0,
            TotalPlayedGames: 0,
            TotalGameWins: 0,
            TotalPlayedFrames: 0,
            TotalFrameWins: 0,
            TotalPlayedTime: 0,
            PlayerGames: []
        },

        contactList: [],

        //------- In Game Controller -------//
        activePlayers: [], //{ Id: "", Name: "", Score: 0, Break: 0, Visiting: false }
        activeGame: null,
        allowSound: true,
        //----------------------------------//

        //------- VAR -------//
        overlayImageCount: 0,
        //------- VAR -------//

        //-- Admin --//

        usersList: [],
        tablesList: [],

        //-- Admin --//

        showPairingQR: false
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            console.log("Connected!")
            if (!state.socket.clubAdmin) {
                Vue.prototype.$socket.send(JSON.stringify({ GameId: state.activeGame.GameId, Type: "Viewer" }));
            }
            else {
                Vue.prototype.$socket.send(JSON.stringify({ ClubId: state.loggedInUser.clubAdmin, Type: "ClubAdmin", Tables: state.socket.clubAdminTables }));
            }

        },
        SOCKET_ONCLOSE(state, event) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            console.log("Message: " + message.data);

            try {
                var obj = JSON.parse(message.data);
                if (obj != null && obj.Type != null && obj.hasOwnProperty("Type") && obj.Type == "InfoFromTable") {
                    if (obj.Data.hasOwnProperty("TotalImages")) {
                        console.log(`Received information about new total images: ${obj.Data.TotalImages}`);
                        state.overlayImageCount = obj.Data.TotalImages;
                    }
                }
                else {
                    if (obj.Type != null && obj.Type == "TableStatus") {
                        if (state.socket.clubAdmin) {
                            eventBus.$emit('ClubAdminDataArrived', obj);
                        }
                    }
                    else {
                        state.activeGame = obj;
                        if (state.activeGame == null) {
                            console.log("Disconnecting...");
                            Vue.prototype.$disconnect();
                        }
                    }
                }

            }
            catch (err) {
                console.log("Error:");
                console.log(err);
            }

        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },

        setIsAnonymous(state, value) {
            state.isAnonymous = value;
        },

        setLoggedInUser(state, user) {
            console.log("User logged in:");

            state.loggedInUser = user;
            console.log(JSON.stringify(state.loggedInUser));
            $cookies.set("loggedUser", user, 60 * 60 * 24 * 30);
            state.isLoggedIn = true;
        },
        setLoggedOutUser(state) {
            var cookiesList = $cookies.keys();
            for (var i = 0; i < cookiesList.length; i++) {
                if (cookiesList[i] != "allowSound")
                    $cookies.remove(cookiesList[i]);
            }
            state.isLoggedIn = false;
            router.push("login");
        },
        setActiveGame(state, game) {
            state.activeGame = game;
        },
        setSetPlayerStats(state, stats) {
            state.playerStats = stats;
        },
        toggleAllowSound(state) {
            state.allowSound = !state.allowSound;
            $cookies.set("allowSound", state.allowSound, 60 * 60 * 24 * 365);
        },
        setShowPairingQR(state, show) {
            state.showPairingQR = show;
        },
        setUsersList(state, list) {
            state.usersList = list;
        },
        setTablesList(state, list) {
            state.tablesList = list;
        },




    },
    getters: {
        getContactList(state) {
            return state.contactList;
        },
        getIsAnonymous(state) {
            return state.isAnonymous;
        },
        // -- Admin -- //
        getUsersList(state) {
            return state.usersList;
        },

        getTablesList(state) {
            return state.tablesList;
        },
        // -- Admin -- //

        getPlayerStats(state) {
            return state.playerStats;
        },
        getShowPairingQR(state) {
            return state.showPairingQR;
        },
        getInputRules(state) {
            var rules = {
                required: value => !!value || i18n.t('required-field'),
                counter: value => value.length <= 20 || i18n.t('max-20-characters'),
                min: v => v.length >= 8 || i18n.t('min-8-characters'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return (value == '') || pattern.test(value) || i18n.t('invalid-email')
                },
                emailObj: obj => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(obj.Email) || pattern.test(obj) || i18n.t('invalid-email')
                }
            };

            return rules;
        },
        getInputRulesHeb(state) {
            var rules = {
                required: value => !!value || 'שדה חובה',
                counter: value => value.length <= 20 || 'Max 20 characters',
                min: v => v.length >= 8 || 'מינימום 8 תוים',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return (value == '') || pattern.test(value) || 'כתובת לא תקינה'
                },
                emailObj: obj => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(obj.Email) || pattern.test(obj) || 'כתובת לא תקינה'
                }
            };

            return rules;
        },
        getCurrentDomain(state) {
            if (process.env.NODE_ENV == "development") {
                
                var domain = "https://snoocare.com";
                if (process.env.VUE_APP_SNOOCARE_DOMAIN != undefined && process.env.VUE_APP_SNOOCARE_DOMAIN != "") 
                    domain = "https://" + process.env.VUE_APP_SNOOCARE_DOMAIN;

                return domain + state.domainRESTPrefix;
            }
            else {
                return state.currentDomain + state.domainRESTPrefix;
            }

        },
        getCleanDomain(state) {
            if (process.env.NODE_ENV == "development") {
                var domain = "https://snoocare.com";
                if (process.env.VUE_APP_SNOOCARE_DOMAIN != undefined && process.env.VUE_APP_SNOOCARE_DOMAIN != "") 
                    domain = "https://" + process.env.VUE_APP_SNOOCARE_DOMAIN;

                return domain;
            } else {
                return state.currentDomain;
            }
        },
        getGeolocation(state) {
            return state.geoLocation;
        },
        getLoggedInUser(state, getters) {
            if (state.loggedInUser != undefined)
                state.loggedInUser.picture = getters.getCurrentDomain + "/user-picture?id=" + state.loggedInUser.id;

            return state.loggedInUser;
        },
        isLoggedIn(state) {
            var user = $cookies.get("loggedUser");
            if (user != null && user != undefined && user != "") {
                state.loggedInUser = user;
                state.isLoggedIn = true;
            } else {
                if (qParams.hasOwnProperty("token")) {
                    user = atob(qParams["token"]);
                    state.loggedInUser = user;
                    $cookies.set("loggedUser", user, 60 * 60 * 24 * 30);
                    state.isLoggedIn = true;
                } else {
                    state.isLoggedIn = false;
                }
            }
            return state.isLoggedIn;
        },

        //------- In Game Controller -------//
        getOverlayImageCount(state) {
            return state.overlayImageCount;
        },
        getActivePlayers(state) {
            return state.activePlayers
        },
        getActiveGame(state) {
            // var game = $cookies.get("activeGame");
            // if (game != null && game != undefined && game != "")
            // {
            //   if (state.activeGame == null)
            //     state.activeGame = game;
            // }
            return state.activeGame;
        },
        getAllowSound(state) {
            var allow = $cookies.get("allowSound");
            if (allow != null && allow != undefined && allow != "") {
                state.allowSound = (allow == "true");
            }

            return state.allowSound;
        },

        //----------------------------------//


    },
    actions: {
        requestContactList(context) {
            Vue.http.get(`${store.getters.getCurrentDomain}/get-contact-list`,
                {
                    params: { id: store.state.loggedInUser.id },
                    headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` }
                }).then(
                    response => {
                        if (response.status == 200) {
                            if (response.body != null) {
                                store.state.contactList = [];
                                for (var i = 0; i < response.body.length; i++) {
                                    store.state.contactList.push({ Name: response.body[i].Name, Phone: response.body[i].Phone });
                                }
                            }
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },
        openSocketAsClubAdmin(context, tables) {
            var domain = "snoocare.com";
            if (process.env.NODE_ENV == "development") {
                if (process.env.VUE_APP_SNOOCARE_DOMAIN != undefined && process.env.VUE_APP_SNOOCARE_DOMAIN != "")
                    domain = process.env.VUE_APP_SNOOCARE_DOMAIN;
            }
            else
                domain = window.location.hostname;
            
            store.state.socket.clubAdmin = true;
            store.state.socket.clubAdminTables = tables;
            Vue.prototype.$connect(`wss://${domain}/datastream?token=${store.state.loggedInUser.token}`);
        },
        sendMessage: function (context, message) {
            console.log("Message: " + message);
            Vue.prototype.$socket.send(message)
        },
        doLoggIn(context, user) {
            context.commit('setLoggedInUser', user);
        },
        doLogOut(context, user) {
            context.commit('setLoggedOutUser');
        },
        showMessageDialog(context, payload) {
            eventBus.$emit('onShowMessageDialog', payload);
        },

        requestGeoLocation(context) {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    store.state.geoLocation = cloneAsObject(position);
                });
            } else {
                console.log("Cannot load geolocation");
            }
        },
        //------- In Game Controller -------//

        toggleVisitPlayer({ commit, dispatch }, visitingPlayer) {
            dispatch('sendMessage', JSON.stringify({ Action: "TOGGLE_VISIT", VisitingPlayer: visitingPlayer }));
        },

        setScore(context, payload) {
            var url = `${store.getters.getCurrentDomain}/game/set-score`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/set-score`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }

            console.log("------------------");
            console.log(store.state.loggedInUser.token);
            console.log("------------------");
            Vue.http.post(url,
                {
                    "gameId": payload.GameId,
                    "score": payload.Score,
                    "player": payload.Player,
                    "foul": payload.IsFoul,
                    "foulWithRed": payload.IsRedFoul,
                    "freeBall": payload.IsFreeball
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);

                        context.dispatch("showMessageDialog", {
                            Title: "Set Score",
                            Body: response.body,
                            Type: "error"
                        });

                        if (response.status == 404) {
                            router.push("mainmenu");
                        }
                    }
                );
        },
        undoLastScore(context, id) {
            var url = `${store.getters.getCurrentDomain}/game/undo`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/undo`;

            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }

            Vue.http.post(url,
                {
                    "gameId": id
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },

        endFrame(context, id) {
            var url = `${store.getters.getCurrentDomain}/game/end-frame`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/end-frame`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }


            Vue.http.post(url,
                {
                    "gameId": id
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },


        reRack(context, id) {
            var url = `${store.getters.getCurrentDomain}/game/rerack`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/rerack`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }

            Vue.http.post(url,
                {
                    "gameId": id
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },

        startFrame(context, id) {
            var url = `${store.getters.getCurrentDomain}/game/start-frame`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/start-frame`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }


            Vue.http.post(url,
                {
                    "gameId": id
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },
        startNewGame({ commit, dispatch }, payload) {
            var isAnonymous = (payload.hasOwnProperty("Anonymous") && payload.Anonymous == true);

            var frames = 0;
            var reds = 10;
            try {
                frames = (payload.Frames == "") ? 0 : parseInt(payload.Frames);
            }
            catch (err) {
                frames = 0;
            }

            try {
                reds = (payload.Reds == "") ? 10 : parseInt(payload.Reds);
            }
            catch (err) {
                reds = 10;
            }

            var url = `${store.getters.getCurrentDomain}/game/create-game`;
            var headers = { 'content-type': 'application/json' };
            var msgBody = {};

            if (isAnonymous) {
                commit('setIsAnonymous', true);

                url = `${store.getters.getCurrentDomain}/anongame/create-game`;
                msgBody = {
                    players: payload.Players,
                    tableCode: payload.TableCode,
                    frames: frames,
                    reds: reds,
                    description: payload.Description
                }
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
                msgBody = {
                    ownerId: store.state.loggedInUser.id,
                    tableId: payload.TableId,
                    tableCode: payload.TableCode,
                    opponent: payload.Opponent,
                    frames: frames,
                    reds: reds,
                    description: payload.Description
                }
            }


            Vue.http.post(url, msgBody,
                {
                    headers: headers
                })
                .then(
                    response => {
                        if (response.status == 200) {
                            commit('setActiveGame', response.body);
                            router.push({ name: "gamecontroller", query: { gameid: response.body.GameId } });
                        }
                    },
                    response => {
                        dispatch("showMessageDialog", {
                            Title: "Create Game Error",
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
        },

        setVisitingPlayer(context, payload) {
            console.log(`isAnonymous : ${store.state.isAnonymous}`);

            var url = `${store.getters.getCurrentDomain}/game/change-visit-player`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/change-visit-player`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }


            Vue.http.post(url,
                {
                    gameId: payload.gameId,
                    player: payload.player
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', response.body);
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },

        updateProfilePicture(context, payload) {
            Vue.http.post(`${store.getters.getCurrentDomain}/update-picture`,
                {
                    id: payload.Id,
                    picture: payload.Picture
                },
                { headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` } })
                .then(
                    response => {
                        if (response.status == 200) {
                            console.log(response.body);
                        }
                    },
                    response => {
                        context.dispatch("showMessageDialog", {
                            Title: "Update Profile Picture",
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
        },

        updateClubLogo(context, payload) {
            Vue.http.post(`${store.getters.getCurrentDomain}/update-club-logo`,
                {
                    id: payload.Id,
                    picture: payload.Picture
                },
                { headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` } })
                .then(
                    response => {
                        if (response.status == 200) {
                            console.log(response.body);
                            for (var i = 0; i < payload.Tables.length; i++) {
                                context.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "DOWNLOAD_NEW_CLUB_LOGO", TablePublicId: payload.Tables[i]}));
                            }
                        }
                    },
                    response => {
                        context.dispatch("showMessageDialog", {
                            Title: "Update Club Logo",
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
        },

        removeClubLogo(context, payload) {
            console.log(payload);
            Vue.http.post(`${store.getters.getCurrentDomain}/remove-club-logo`,
                {
                    id: payload.Id,
                },
                { headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` } })
                .then(
                    response => {
                        if (response.status == 200) {
                            console.log(response.body);
                            for (var i = 0; i < payload.Tables.length; i++) {
                                context.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "REMOVE_CLUB_LOGO", TablePublicId: payload.Tables[i]}));
                            }
                        }
                    },
                    response => {
                        context.dispatch("showMessageDialog", {
                            Title: "Remove Club Logo",
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
        },        

        endGame(context, id) {
            console.log(`End game: ${id}`);
            var url = `${store.getters.getCurrentDomain}/game/end-game`;
            var headers = { 'content-type': 'application/json' };

            if (store.state.isAnonymous) {
                url = `${store.getters.getCurrentDomain}/anongame/end-game`;
            }
            else {
                headers['Authorization'] = `Bearer ${store.state.loggedInUser.token}`;
            }


            Vue.http.post(url,
                {
                    gameId: id
                },
                { headers: headers })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.commit('setActiveGame', null);
                        }
                    },
                    response => {
                        context.dispatch("showMessageDialog", {
                            Title: "End Game",
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
        },

        requestGameInfo(context, gameid) {
            if (typeof gameid != 'undefined' && gameid != "" && !store.state.isLoggedIn) {
                store.commit('setIsAnonymous', true);
                Vue.http.get(`${store.getters.getCurrentDomain}/anongame/live-game-info`,
                    {
                        params: { gameId: gameid },
                        headers: { 'content-type': 'application/json' }
                    })
                    .then(
                        response => {
                            if (response.status == 200) {
                                if (response.body != null) {
                                    context.commit('setActiveGame', response.body);
                                    eventBus.$emit('onFoundGame');
                                }
                            }
                        },
                        response => {
                            console.error(response.body);
                        }
                    );
            }
            else {
                Vue.http.post(`${store.getters.getCurrentDomain}/game/search-game`,
                    {
                        "playerId": store.state.loggedInUser.id
                    },
                    { headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` } })
                    .then(
                        response => {
                            if (response.status == 200) {
                                console.log("Active Games:");
                                console.log(response.body);
                                context.commit('setActiveGame', response.body);
                                eventBus.$emit('onFoundGame');
                            }
                        },
                        response => {
                            console.error(response.body);
                        }
                    );
            }
        },

        requestPlayerStats(context) {
            Vue.http.get(`${store.getters.getCurrentDomain}/get-player-stats`,
                {
                    params: { playerId: store.state.loggedInUser.id },
                    headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` }
                })
                .then(
                    response => {
                        if (response.status == 200) {
                            if (response.body != null) {
                                context.commit('setSetPlayerStats', response.body);
                            }
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );

        },

        requestReplay(context, show) {
            Vue.prototype.$socket.send(JSON.stringify({ GameId: store.state.activeGame.GameId, Type: "CommandToTable", Command: "REPLAY", Params: [] }));
        },

        //------------ VAR ------------//

        toggleVAR(context, show) {
            Vue.prototype.$socket.send(JSON.stringify({ GameId: store.state.activeGame.GameId, Type: "CommandToTable", Command: show ? "VAR_ON" : "VAR_OFF", Params: [] }));
        },
        showOverlayImage(context, index) {
            Vue.prototype.$socket.send(JSON.stringify({ GameId: store.state.activeGame.GameId, Type: "CommandToTable", Command: "VAR_IMAGE_INDEX", Params: [index] }));
        },
        changeOverlayOpacity(context, opacity) {
            Vue.prototype.$socket.send(JSON.stringify({ GameId: store.state.activeGame.GameId, Type: "CommandToTable", Command: "VAR_IMAGE_OPACITY", Params: [opacity] }));
        },
        showOverlayLiveFeed({ commit, dispatch }, show) {
            Vue.prototype.$socket.send(JSON.stringify({ GameId: store.state.activeGame.GameId, Type: "CommandToTable", Command: "VAR_SHOW_LIVE_FEED", Params: [show] }));
        },


        //------------ VAR ------------//


        // -- Admin -- //
        requestUserList({ commit, dispatch }) {
            Vue.http.get(`${store.getters.getCurrentDomain}/admin/get-all-users`,
                {
                    headers: {
                        'Authorization': `Bearer ${store.state.loggedInUser.token}`
                    }
                })
                .then(
                    response => {
                        if (response.status == 200) {
                            if (response.body != null) {
                                commit('setUsersList', response.body);
                            }
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },

        requestTablesList({ commit, dispatch }) {
            Vue.http.get(`${store.getters.getCurrentDomain}/admin/get-all-tables`,
                {
                    headers: {
                        'Authorization': `Bearer ${store.state.loggedInUser.token}`
                    }
                })
                .then(
                    response => {
                        if (response.status == 200) {
                            if (response.body != null) {
                                commit('setTablesList', response.body);
                            }
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        },

        manualConfirm(context, id) {
            Vue.http.post(`${store.getters.getCurrentDomain}/admin/manual-confirm`,
                {
                    "id": id,
                },
                { headers: { 'Authorization': `Bearer ${store.state.loggedInUser.token}` } })
                .then(
                    response => {
                        if (response.status == 200) {
                            context.dispatch("requestUserList");
                        }
                    },
                    response => {
                        console.error(response.body);
                    }
                );
        }
        // -- Admin -- //    





        //----------------------------------//

    }
})
